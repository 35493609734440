<template>
  <div class="d-flex bg-white row min-vh-100">
    <div class="container">
      <div
        class="d-flex flex-row justify-content-center align-self-center fadeInUp"
      >
        <div class="col-12 col-md-6 col-lg-5 py-4 py-md-10">
          <div class="text-center mb-6">
            <a class="mb-5 d-block" :href="'/'">
              <Logo />
            </a>
            <form
              v-if="!validationFailed"
              class="form-signin"
              action="#"
              method="POST"
              @submit.prevent="savePassword"
            >
              <h1 class="h3 mb-3 font-weight-bold">
                Please choose a password
              </h1>
              <br />
              <div
                class="form-group col-md-12 required"
                :class="{ 'is-invalid': errors.has('password') }"
              >
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  class="form-control mb-3"
                  placeholder="Password"
                  required
                  autofocus
                />
                <input
                  id="password-confirm"
                  v-model="password_confirm"
                  type="password"
                  class="form-control"
                  placeholder="Password confirm"
                  required
                  autofocus
                />
                <span
                  v-show="errors.has('password')"
                  class="invalid-feedback"
                  >{{ errors.first("password") }}</span
                >
              </div>
              <br />
              <div class="form-group col-md-12 required">
                <button class="btn btn-lg btn-primary btn-block" type="submit">
                  Sign in
                </button>
              </div>
            </form>
            <div v-if="validationFailed">
              <h2 class="mb-2 font-weight-bold">
                The entered token is invalid.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsonAPI from "@/api";
import Logo from "@/components/Svg/Logo";

export default {
  name: "Invite",
  components: {
    Logo,
  },
  beforeRouteEnter(to, from, next) {
    const token = to.params.token;
    jsonAPI
      .post("invite/validation", { token: token })
      .then(() => {
        next((vm) => {
          vm.token = token;
        });
      })
      .catch(() => {
        next((vm) => {
          vm.validationFailed = true;
        });
      });
  },
  data() {
    return {
      token: "",
      password: "",
      password_confirm: "",
      validationFailed: false,
    };
  },
  methods: {
    savePassword() {
      jsonAPI
        .post("invite/accept/" + this.token, {
          password: this.password,
          password_confirmation: this.password_confirm,
        })
        .then(() => {
          this.$router.push({ name: "login" });
          window.Bus.$emit("flash-message", {
            text: "Password updated",
            type: "success",
          });
        })
        .catch((error) => {
          Object.keys(error.response.data.errors).forEach((key) => {
            this.$validator.errors.add({
              field: key,
              msg: error.response.data.errors[key][0],
            });
          });
          window.Bus.$emit("flash-message", {
            text: "Password error",
            type: "error",
          });
        });
    },
  },
};
</script>
